input{
   height: 4vh; 
}

.button_login{
  background-color: #fff;
  border: 1px solid #d5d9d9;
  border-radius: 8px;
  box-shadow: rgba(213, 217, 217, .5) 0 2px 5px 0;
  box-sizing: border-box;
  color: #0f1111;
  cursor: pointer;
  display: inline-block;
  font-family: 'Brygada 1918', serif;
  font-size: 15px;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
  width: 100px;
}

.header_login{
    position: sticky;
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    height: 10%;
}

.logo{
    height: 6vh;
}

.login_content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form_signup{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.question{
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 2vh;
}

.login_label{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    color: #D1C9B5; 
    font-size: 20px; 
    padding: 1vh;
}

.error_message{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    color: #cb3535; 
    display: flex;
    justify-content: center;
    align-items: center;
}