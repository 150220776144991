:root{
    --gold_color: #D1C9B5;
    --background_color: #01152E;
}

.gallery_page{
    background-color: var(--background_color);
    height: 100vh;
}

/* HEADER - menu icon + logo */
.header_style{
    position: sticky;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    color: white;
    height: 10%;
    background-color: var(--background_color);
    padding-top: 2vh;
}

.headerBack{
    width: 7vh;
    height: 70%;
    color: #000914;
}

.logo_camera{
    margin-left: 13vh;
    height: 6vh;
}

.gallery_no_image{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 30vh;
}

.gallery_quote{
    color: var(--gold_color);
    font-size: 20px;
    font-family: 'Brygada 1918';
    font-weight: 500;
    word-wrap: break-word;
    display: flex;
    justify-content: center;
    text-align: center;
}

.img {
    width: 100vh;
}

.modal{
    background-color: var(--gold_color);
    border-radius: 8px;
    padding-bottom: 0vh;
}
.modal_image{
    border-radius: 10px;
    height: 85%;
    width: 100%;
}
.info{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: fit-content;
}
.modal_close{
    height: 30px;
    width: 30px;
    margin-bottom: 1vh;
}
.author_info{
    font-size: 20px;
    font-family: 'Brygada 1918';
}