:root{
    --gold_color: #D1C9B5;
    --background_color: #01152E;
}

.about{
    overflow: scroll;
    padding-bottom: 10vh;
}

.aboutContent{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

.aboutText{
    font-family: 'Brygada 1918', serif;
    width: 40vh; 
    text-align: left; 
    color: var(--gold_color); 
    font-size: 22px; 
    word-wrap: break-word;
}