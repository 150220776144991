.Page_register{
    overflow: scroll;
    background-color: #01152E;
}

.header_login{
    position: sticky;
    margin-top: 4vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    height: 10%;
}

.logo{
    height: 6vh;
}

.login_content{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 90vh;
}

.form_signup{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.question{
    display: flex;
    justify-content: start;
    flex-direction: column;
    padding: 2vh;
}

.login_label{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    color: #D1C9B5; 
    font-size: 20px; 
    padding: 1vh;
}

.register_label{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    color: #D1C9B5; 
    font-size: 20px; 
    padding: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.error_message{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    color: #cb3535; 
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-height: 740px) {
    .register_label{
        padding: 0vh;
        margin-top: 0vh;
    }
    .login_content{
        max-height: fit-content;
        margin-bottom: 5vh;
    }
}