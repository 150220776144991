/* CAMERA SCREEN */
:root{
    --gold_color: #D1C9B5;
    --background_color: #01152E;
}

.line{
    margin-left: 15vh;
    width: 25vh;
    border: 1px var(--gold_color) solid;
}

.Page{
    overflow: hidden;
    background-color: #01152E;
    height: 100vh;
}

/* select name */
.camera_content{
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.camera_text{
    color: var(--gold_color);
    font-size: 36px;
    font-family: 'Brygada 1918';
    font-weight: 500;
    word-wrap: break-word;
    margin-bottom: 10vh;
}

.selectName{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.select{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1vh;
    height: 7vh;
    width: 30vh;
    color: var(--background_color);
    font-size: 18px;
    background-color: var(--gold_color);
    font-weight: 500;
}

.chooseBtn {
  background-color: var(--gold_color);
  border: 1px solid #d5d9d9;
  border-radius: 8px;  
  font-family: "Amazon Ember",sans-serif;
  line-height: 29px;
  padding: 0 10px 0 11px;
  position: relative;
  text-align: center;
  touch-action: manipulation;
}


/* camera */
.cameraContent{
    overflow: hidden;
}

.cameraDiv{
    z-index: 2;
    height: 20vh;
    width: 100%;
    position: fixed;
    bottom: 5vh;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}
.cameraItem{
    height: 40vh;
    width: 15vh;
    color: #d1c9b5;
    z-index: 1;
    padding-left: 12vh;
}

.switchCamera{
    padding-left: 4vh;
    height: 30vh;
    width: 10vh;
    color: #d1c9b5;
    z-index: 1;
}

.close_button{
    margin-top: 5vh;
    height: 30vh;
    width: 10vh;
    z-index: 1;
    color: #d1c9b5;
}

.closeDiv{
    z-index: 2;
    height: 20vh;
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: end;
}