@import url('https://fonts.googleapis.com/css2?family=Brygada+1918:wght@500&display=swap');
/* SHAPES */
.menuCircle {
    background: #cab397d6;
    border-radius: 50%;
    width: 11vh;
    height: 11vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 15px 0px rgba(13, 12, 12, 0.5); }

.menuCircle p{
    text-align: center;
}

/* MAIN */
.Page{
    overflow: hidden;
    background-color: #01152E;
}

.App{
    overflow: hidden;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.background{
    display: flex;
    flex-direction: column;
    position: fixed;
}

.decorations{
    width: 57vh;
}

.svjetlo{
    position: fixed;
    left: -7vh;
    width: 70vh;
    height: 60vh;
    clip-path: polygon(0% 100%, 50% 0%, 100% 100%);
    transform: rotate(180deg);
    background: linear-gradient(180deg, #E0BD93 16%, rgba(228.44, 209.24, 186.56, 0) 100%);
}

.camera{
    position: fixed;
    margin-top: 45vh;
    width: 65vh; 
    height: 30vh; 
    left: -2vh;
}

/* HEADER - menu icon + logo */
.header{
    position: sticky;
    margin-top: 2vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: white;
    height: 10%;
}

.headerMenu{
    width: 5vh;
    height: 100%;
    color: #000914;
}

.logo{
    height: 6vh;
}


/* BODY */
.mainContent{
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90vh;
}

.mainContentItem{
    margin-bottom: 20px;
    padding: 2vh; 
}

/* Text */
.mainText{
    font-family: 'Brygada 1918', serif;
    width: 30vh; 
    text-align: center; 
    color: #000E1F; 
    font-size: 22px; 
    font-weight: 550; 
    word-wrap: break-word;
}

/* MENU */
.mainMenu{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    position: relative;
    width: 50vh;
}

.menuItem{
    font-family: 'Brygada 1918', serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.menuIcon{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: rgb(24, 23, 23);
    position: relative;
    height: 7vh;
    width: 7vh;
}

.menuText{
    color: #cab397d6;
}

a{
    text-decoration: none;
    color: inherit;
}

@media (min-height: 740px) {
    .decorations{
        width: 47vh;
    }
    .svjetlo{
        left: -12vh;
    }
    .camera{
        left: -8vh;
    }
    .mainMenu{
        width: 40vh;
    }
    .mainText{
        font-size: 26px;
    }
  }

  @media (max-width: 320px) {
    .decorations{
        width: 47vh;
    }
    .svjetlo{
        left: -12vh;
    }
    .camera{
        left: -8vh;
    }
    .mainMenu{
        width: 40vh;
    }
    .mainText{
        font-size: 22px;
    }
  }

